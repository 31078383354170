/*--------------------------------------------------------*\
  Team
\*--------------------------------------------------------*/

// Settings
$animation-duration: 250ms;
$animation-easing: ease-in-out;
$team-item-name-height: 2rem;


.block-team .block-main {
  position: relative;
  &.open {
    .team-item-name {
      opacity: 0;
    }
    .team-item:not(.open) {
      @include mq($until: mobile) {
        transition: none;
      }

      opacity: 0;
      pointer-events: none;
      transition: all $animation-duration $animation-easing;
    }
    .team-item:not(.open) canvas {
      opacity: 0;
      &.blue {
        @include mq($until: mobile) {
          transition: none;
        }

        opacity: 0;
        transition: all $animation-duration $animation-easing;
      }
    }
  }
  .sort-item-mobile-header {
    @include mq($until: mobile) {
      //&:not(.active) ~ .sort-item {
      // @extend .team-item.hide;
      //}
    }
  }
}

.block-header {
  .sort {
    @include rg(1em, 1.25em);

    text-align: center;
    a {
      color: $body;
      &:hover {
        color: $hover-color;
      }
    }
    a ~ a {
      margin-left: 1em;
      padding-left: 1em;

      border-left: 1px solid $body;
    }
    &.active {
      border-left-color: rgba($body, .3);
      a:not(.active) {
        color: rgba($body, .3);
        &:hover {
          color: $hover-color;
        }
      }
    }
  }
}


.team-item {
  @include mq($until: at-800) {
    width: 48%;
    max-width: 48%;
  }
  @include mq($until: mobile) {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;

    transition: none;
  }

  position: relative;

  display: inline-block;
  vertical-align: bottom;
  // Columns Size
  width: 31.3%;
  max-width: 31.3%;
  margin-top: 2%;
  margin-right: 1%;
  margin-left: 1%;

  transform: translateZ(0);
  transform-origin: 0 50%;
  transition: max-width 250ms $timing, width 500ms ease-in-out, transform 500ms ease-in-out, max-height 250ms $timing, height 250ms $timing;
  &.hide {
    @include mq($until: mobile) {
      width: 100%;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
    }

    overflow: hidden;
    width: 0;
    max-width: 0;
    margin-right: 0;
    margin-left: 0;

    transform: translateZ(0);
    & + .item-popup {
      @include mq($until: mobile) {
        transition: none;
      }

      display: block;
      height: 0;
      margin-top: 0;

      opacity: 0;
      transform-origin: 0 50%;
      transition: all $animation-duration $animation-easing, display 0;
    }
  }
  .team-item-name {
    @include rg(1.25rem, $team-item-name-height);

    white-space: nowrap;
  }
  &.open + .item-popup {
    @include mq($until: mobile) {
      transition: none;
    }

    z-index: 10;

    display: block;

    opacity: 1;
    transition: all $animation-duration $animation-easing, display 0;
  }
  & > img {
    position: absolute;
    z-index: -1;
    top: 0;

    opacity: 0;
  }
  canvas {
    @include mq($until: mobile) {
      display: block;
      width: calc(100% + 3rem);
      max-width: 10000%;
      margin: 0 -1.5rem .5rem;
    }

    display: block;
    width: auto;
    max-width: 100%;

    cursor: pointer;
    &.blue {
      @include mq($until: mobile) {
        display: none;
      }

      transition-delay: $animation-duration;
      transition-duration: 0;
    }
    &.grey {
      @include mq($until: mobile) {
        position: relative;

        transition-duration: 0ms;
      }

      position: absolute;
      top: 0;

      transition: all $animation-duration $animation-easing;
    }
  }
  &:hover, &.open {
    .grey {
      @include mq($until: mobile) {
        opacity: 1;
      }

      opacity: 0;
    }
    .blue {
      transition: all $animation-duration $animation-easing;
    }
  }
}

.team-popup {
  @include mq($until: mobile) {
    margin-top: 0;
  }

  margin-top: -$team-item-name-height;
  header {
    .job-title {
      @include rg(1.25rem, 3rem);

      margin-left: .5rem;
      padding-left: .5rem;

      border-left: 2px solid $body;
    }
  }
  article {
    .social {
      @include mq($until: mobile) {
        display: none;
      }

      position: relative;
      right: auto;
      .btn {
        position: relative;

        width: 2rem;
        height: 2rem;
        margin: 0 .5rem 0 0;
        padding: 1rem;
        svg {
          @include center;

          height: 1rem;
          path {
            fill: $body;
          }
        }
      }
    }
  }
  aside {
    text-align: center;
    ul {
      margin-bottom: 2rem;
    }
    li {
      position: relative;
      z-index: 100;

      display: block;
      margin: 1.25rem 0;
      a {
        display: block;
        max-height: 2.5em;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: grayscale(100%);
        transition: filter 250ms;
        img {
          visibility: hidden;
          height: 70%;
        }
        &:hover {
          filter: grayscale(0%);
        }
      }
    }
  }
}
