/*--------------------------------------------------------*\
  Banner
\*--------------------------------------------------------*/

.block.block-banner {
  @include mq($until: mobile) {
    height: auto;
    padding-bottom: 0 !important;
  }

  height: 32rem;
  max-height: 100%;
  padding: 0;
  &.main {
    height: 100%;
    .slidr-banner-item {
      .bg-image {
        @include mq($until: mobile) {
          display: none;
        }
      }
      .ctn {
        @include mq($until: mobile) {
          height: 100%;

          background-color: transparent !important;
        }
      }
    }
    .banner-title {
      @include heading(4.75em, 1.125);
    }
  }
  &:before {
    @include mq($until: mobile) {
      padding-top: 0 !important;
    }
  }
  & + .block {
    @include mq($until: mobile-nav) {
      padding: 1.5rem 0 3.5rem !important;
    }
    @include mq($until: mobile) {
      padding: 0 0 3.5rem !important;
    }

    padding: 3.5rem 0 7.5rem !important;
  }
}

.slidr-banner {
  display: inline-block;
  float: left;
  width: 100% !important;
  height: 100% !important;
}

.slidr-banner-item {
  @include span(24);
  @include bg-center;

  overflow: hidden;
  height: 100%;

  background-size: cover;
  .bg-image {
    @include mq($until: mobile) {
      display: block;
    }

    display: none;
  }
  video {
    @include mq($until: tablet) {
      display: none;
    }

    position: absolute;
    top: 50%;
    left: 50%;

    overflow: hidden;
    min-width: 100%;
    min-height: 100%;

    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
  }
  .ctn {
    @include mq($until: mobile) {
      position: relative;

      height: auto;
      padding: 1.5em;

      background-color: $blue-dk !important;
    }

    height: 100%;
    .btn {
      color: white !important;

      border-color: white !important;
      div[class^=border] {
        background-color: white !important;
      }
      &:hover {
        border-color: transparent !important;
      }
    }
  }
}


.block.block-banner:not(.main) .slidr-banner {
  @include mq($until: mobile) {
    position: relative !important;
  }
}


.block.block-banner:not(.main) .slidr-banner-item {
  @include mq($until: mobile) {
    background: $blue-dk !important;
  }
  .slidr-banner {
    @include mq($until: mobile) {
      position: relative !important;
    }
  }
  .banner-content {
    @include mq($until: mobile) {
      position: relative;

      width: 100%;

      background-color: $blue-dk !important;
    }
    &.top {
      @include mq(mobile) {
        top: 8em;
      }
    }
    &.middle {
      @include mq($until: mobile) {
        position: relative;
        top: auto;
      }
    }
    &.bottom {
      @include mq(mobile) {
        bottom: 0;
      }
    }
    &.centered {
      @include mq(mobile) {
        @include h-center;
      }
    }
    &.middle {
      @include mq(mobile) {
        @include v-center;
      }
      &.centered {
        @include mq(mobile) {
          @include center;
        }
      }
    }
  }
}

.block.block-banner.main {
  padding-bottom: 0 !important;
}

.block.block-banner.main .slidr-banner-item {
  .banner-content {
    &.top {
      top: 8em;
    }
    &.centered {
      @include h-center;
    }
    &.middle {
      @include v-center;
      &.centered {
        @include center;
      }
    }
    &.bottom {
      bottom: 5em;
    }
    &.left {
      .btn-ctn {
        text-align: left;
      }
    }
  }
}

.banner-content {
  @include mq($until: 800px) {
    font-size: 10px;
  }
  @include mq($until: mobile) {
    font-size: 8px;
  }

  color: #fff;

  position: absolute;
  z-index: 10;

  width: calc( 100% - 7rem );
  &.text-dark {
    color: $body;
  }
  &.centered {
    text-align: center;
    .banner-subtitle {
      float: none;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &.banner-quote {
    &:first-child {
      .first-letter {
        position: relative;
        &:before {
          @include mq($until: mobile) {
            top: -.2em;
          }

          position: absolute;
          z-index: 1;
          top: -.5em;
          left: -1em;

          display: block;
          width: 1.4em;
          height: 1.4em;

          background: url('./img/svg/quote-begin-white.svg') no-repeat top left;
          background-size: contain;
          content: '';
          opacity: .25;
        }
      }
    }
  }
}


.tablet-bg-image {
  @include mq(tablet) {
    background-image: url(#);
    .bg-image {
      visibility: visible;
    }
  }
  @include mq($until: mobile) {
    background-image: url(#) !important;
  }

  position: absolute;

  width: 100%;
  height: 100%;

  background-position: bottom center;
  background-size: cover;
  .bg-image {
    visibility: hidden;
  }
}

.mobile-bg-image {
  @include mq(mobile) {
    background-image: url(#);
    .bg-image {
      visibility: visible;
    }
  }

  position: absolute;

  width: 100%;
  height: 100%;

  background-position: bottom center;
  background-size: cover;
  .bg-image {
    visibility: hidden;
  }
}

.banner-title {
  @include span(24);
  @include heading(5em, 1.125);
}

.banner-subtitle {
  @include span(20);
  @include lt(2em, 1.375);
}

.banner-subtext, .banner-copy, .banner-logo {
  @include span(24);
  @include lt(1.25em, 1.25);

  margin-top: 1.5em;
  strong {
    @include bd(1em, 1);
  }
}

.banner-copy {
  p {
    margin: 0 0 .5em;
  }
  img {
    @include mq($until: mobile) {
      transform: scale3d(.5, .5, 1);
    }

    display: inline-block;
  }
}

.home {
  .block-banner {
    .banner-title span {
      opacity: 0;
      transition: opacity 250ms ease-in-out 250ms;
      & + span {
        transition-delay: 1000ms;
      }
    }
    .banner-subtext {
      opacity: 0;
      transition: opacity 250ms ease-in-out 1750ms;
    }
  }
  .clean-load {
    .block-banner {
      .banner-title span, .banner-subtext {
        opacity: 1;
      }
    }
  }
}
