/*--------------------------------------------------------*\
	Locations
\*--------------------------------------------------------*/

.block.block-banner + .block.block-location, .block.block-banner ~ .block.block-location {
  @include mq($until: at-768) {
    padding: 3.5rem 0 !important;
  }

  padding: 3.5em 0 !important;
}

.block-location {
  @include mq($until: at-768) {
    width: 100%;
  }

  display: inline-block;
  vertical-align: top;
  float: none;
  box-sizing: border-box;
  width: 40%;
  .ctn {
    padding: 0 0rem;
  }
  + .block-location {
    padding: 3.5em 0;
  }
  .btn-ctn {
    text-align: center;

    margin-top: 0;
  }
  .btn-ctn a {
    display: inline-block;
    margin: 0 4em 5em;
  }
  p {
    text-align: center;

    margin: 0 0 1.5em;
  }
  .map-image {
    display: inline-block;
    width: auto;
    margin: 0 auto;

    background: $blue;
    img {
      display: block;
      width: auto;
      max-width: 100%;
      margin: 0 auto;

      transition: opacity 150ms ease-in-out;;
    }
    &:hover {
      img {
        opacity: .33;
      }
    }
  }
}
