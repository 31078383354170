/*--------------------------------------------------------*\
  Graphic
\*--------------------------------------------------------*/

.block.block-graphic {
  @include mq($until: mobile) {
    margin-top: -4rem;
  }

  z-index: 1;

  margin-top: -12rem;
  margin-bottom: -3px;
  padding: 0 !important;

  background-color: transparent;
  & > svg {
    * {
      fill: #f2f2f3;
    }
  }
  &.about {
    & > svg {
      * {
        fill: #649dc8;
      }
    }
  }
}
