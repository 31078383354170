/*--------------------------------------------------------*\
	Appearing Content
\*--------------------------------------------------------*/

.block-banner + .to-appear ~ .to-appear {
  .ctn {
    opacity: 0;
    transition: opacity 750ms;
    &.appear {
      opacity: 1;
    }
    @include mq($until: mobile) {
      opacity: 1;
    }
  }

}

.block-banner ~ .block-location.to-appear {
  .ctn {
    opacity: 1 !important;
    .btn-ctn {
      opacity: 0;
      transition: opacity 750ms;
      @include mq($until: mobile) {
        opacity: 1;
      }
    }
    &.appear {
      .btn-ctn {
        opacity: 1;
      }
    }
  }
}