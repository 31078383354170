/*--------------------------------------------------------*\
	Libs
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
	Susy // Breakpoint
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
	Mains
\*--------------------------------------------------------*/
/* line 13, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* line 21, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
html {
  line-height: 1; }

/* line 24, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
ol, ul {
  list-style: none; }

/* line 27, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 31, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

/* line 36, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
q, blockquote {
  quotes: none; }

/* line 39, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

/* line 43, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
a img {
  border: none; }

/* line 46, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_reset.scss */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

/*--------------------------------------------------------*\
	Colors
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
	Fonts -> Heading
\*--------------------------------------------------------*/
@font-face {
  font-family: 'gunny_rewrittenregular';
  src: url('./fonts/gnyrwn971-webfont.eot');
  src: url('./fonts/gnyrwn971-webfont.eot?#iefix') format('embedded-opentype'), url('./fonts/gnyrwn971-webfont.woff2') format('woff2'), url('./fonts/gnyrwn971-webfont.woff') format('woff'), url('./fonts/gnyrwn971-webfont.ttf') format('truetype'), url('./fonts/gnyrwn971-webfont.svg#gunny_rewrittenregular') format('svg');
  font-weight: normal;
  font-style: normal; }

/*--------------------------------------------------------*\
	Fonts -> Body
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
	Icons
\*--------------------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon.eot?a0jczb');
  src: url('../assets/fonts/icomoon.eot?#iefixa0jczb') format('embedded-opentype'), url('../assets/fonts/icomoon.woff?a0jczb') format('woff'), url('../assets/fonts/icomoon.ttf?a0jczb') format('truetype'), url('../assets/fonts/icomoon.svg?a0jczb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal; }

/* line 16, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_icons.scss */
[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 28, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_icons.scss */
.icon-file::before {
  content: "\e600"; }

/* line 31, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_icons.scss */
.icon-arrow-right::before {
  content: "\e601"; }

/* line 34, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_icons.scss */
.icon-mail::before {
  content: "\e602"; }

/* line 37, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_icons.scss */
.icon-twitter::before {
  content: "\e603"; }

/* line 40, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_icons.scss */
.icon-facebook::before {
  content: "\e604"; }

/* line 43, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_icons.scss */
.icon-linkedin::before {
  content: "\e605"; }

/*--------------------------------------------------------*\
  Positioning
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
  Align with .ctn
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
  Maintain an aspect ratio
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
	Universals
\*--------------------------------------------------------*/
/* line 5, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
html {
  font-size: 16px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 12, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
html, body, .wrapper {
  height: 100%;
  background: #fff; }

/* line 17, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
*, *::before, *::after {
  box-sizing: inherit;
  -webkit-font-smoothing: inherit; }

/* line 22, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
a {
  transition: all 0.75s cubic-bezier(.19, 1, .22, 1);
  text-decoration: none; }

/* line 27, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
.wrapper {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  /* line 12, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/lib/susy/output/support/_clearfix.scss */
  .wrapper:after {
    content: " ";
    display: block;
    clear: both; }

/* line 37, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
img {
  max-width: 100%;
  height: auto;
  display: block; }

/* line 43, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
.ctn {
  max-width: 74rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 0 3.5rem; }
  /* line 12, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/lib/susy/output/support/_clearfix.scss */
  .ctn:after {
    content: " ";
    display: block;
    clear: both; }
  @media (max-width: 39.99em) {
    .ctn {
      padding: 0 2rem; } }
  @media (max-width: 29.99em) {
    .ctn {
      padding: 0 1.5rem; } }

/* line 55, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_universals.scss */
.full {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

/*--------------------------------------------------------*\
   Site
\*--------------------------------------------------------*/
/*--------------------------------------------------------*\
  Header
\*--------------------------------------------------------*/
/* line 5, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
header.header {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 56px; }
  @media (max-width: 57.8025em) {
    header.header {
      overflow: visible; } }
  /* line 18, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
  header.header .logo {
    position: relative;
    float: left;
    width: 93px;
    max-width: 213px;
    height: 56px;
    background: url('./img/ignition-logo-2x.png') no-repeat center center;
    background-size: cover; }
    /* line 28, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header .logo img {
      display: none;
      width: auto;
      max-width: 100%; }
    /* line 33, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header .logo:before, header.header .logo:after {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 10000%;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4fwYAAs0BzF1VEXcAAAAASUVORK5CYII=");
      content: '';
      pointer-events: none; }
    /* line 48, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header .logo:after {
      right: 10000%;
      left: 100%; }
      @media (max-width: 57.8025em) {
        header.header .logo:after {
          right: 0;
          width: calc(100vw - 93px - 3.5rem); } }
      @media (max-width: 39.99em) {
        header.header .logo:after {
          width: calc(100vw - 93px - 2rem); } }
      @media (max-width: 29.99em) {
        header.header .logo:after {
          width: calc(100vw - 93px - 1.5rem); } }
    /* line 64, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header .logo a {
      display: block;
      height: 56px; }
    /* line 68, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header .logo .motto {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      z-index: 200;
      left: 105%;
      overflow: hidden;
      width: 125%;
      margin-top: 2px; }
      /* line 78, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
      header.header .logo .motto svg {
        transform: translate(-100%, 125%);
        transition: all 250ms cubic-bezier(.19, 1, .22, 1); }
    /* line 85, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header .logo:hover .motto svg {
      transform: translate(0, 125%); }
  /* line 91, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
  header.header nav.nav {
    font-size: 1em;
    line-height: 1em;
    font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
    position: relative;
    float: right;
    margin-top: -10em; }
    @media (max-width: 57.8025em) {
      header.header nav.nav {
        position: absolute;
        top: 56px;
        right: 0;
        left: 0;
        float: none;
        height: auto;
        margin-top: 0;
        pointer-events: none; } }
    @media (max-width: 57.8025em) {
      header.header nav.nav .menu-main-navigation-container {
        overflow: hidden; } }
    @media (max-width: 57.8025em) {
      header.header nav.nav ul {
        border-top: 1px solid #fff;
        transform: translate3d(0, -100%, 0);
        transition: transform 250ms ease-out; } }
    /* line 122, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header nav.nav li {
      float: left;
      margin-right: 3em;
      opacity: 0;
      transform: translateX(3em); }
      @media (max-width: 57.8025em) {
        header.header nav.nav li {
          text-align: center;
          float: none;
          margin: 0;
          background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4fwYAAs0BzF1VEXcAAAAASUVORK5CYII=");
          border-bottom: 1px solid #fff;
          opacity: 1;
          transform: translateX(0); } }
      /* line 140, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
      header.header nav.nav li a {
        color: black;
        display: block;
        transition: all 100ms; }
        @media (max-width: 57.8025em) {
          header.header nav.nav li a {
            padding: 1em; } }
        /* line 150, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
        header.header nav.nav li a:hover {
          color: #B0171E; }
      /* line 154, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
      header.header nav.nav li.current_page_item a {
        color: #B0171E; }
  /* line 159, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
  header.header #nav-toggle.active + nav.nav {
    margin-top: 20px;
    transition-delay: 0; }
    @media (max-width: 57.8025em) {
      header.header #nav-toggle.active + nav.nav {
        margin-top: 0;
        pointer-events: all; } }
    /* line 169, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle.active + nav.nav li {
      opacity: 1;
      transform: translateX(0);
      transition: all 250ms cubic-bezier(.175, .885, .345, 1.48) 50ms; }
      /* line 173, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
      header.header #nav-toggle.active + nav.nav li + li {
        transition-delay: 100ms; }
        /* line 175, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
        header.header #nav-toggle.active + nav.nav li + li + li {
          transition-delay: 150ms; }
          /* line 177, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
          header.header #nav-toggle.active + nav.nav li + li + li + li {
            transition-delay: 190ms; }
            /* line 179, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
            header.header #nav-toggle.active + nav.nav li + li + li + li + li {
              transition-delay: 230ms; }
    @media (max-width: 57.8025em) {
      header.header #nav-toggle.active + nav.nav ul {
        transform: translate3d(0, 0, 0);
        transition-delay: 0ms !important;
        transition-timing-function: ease-in-out !important; } }
  /* line 194, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
  header.header #nav-toggle {
    font-size: 1em;
    line-height: 1em;
    font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
    position: relative;
    float: right;
    margin-top: 16px;
    padding: 10px 35px 16px 0;
    cursor: pointer;
    transition: all 250ms ease-out; }
    /* line 205, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle .icon, header.header #nav-toggle .icon:before, header.header #nav-toggle .icon:after {
      position: absolute;
      display: block;
      width: 25px;
      height: 2px;
      background: black;
      border-radius: 1px;
      content: '';
      cursor: pointer;
      transition: all 250ms ease-out; }
    /* line 219, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle .icon:before {
      top: -8px; }
    /* line 222, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle .icon:after {
      bottom: -8px; }
    /* line 226, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle .label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: black;
      position: absolute;
      right: calc(100% + 10px);
      visibility: hidden;
      margin-top: -2px;
      transition: all 150ms ease-out 150ms; }
    /* line 241, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle:hover .icon:before {
      top: -6px; }
    /* line 244, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle:hover .icon:after {
      bottom: -6px; }
    /* line 250, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle.active .icon {
      background-color: transparent; }
      /* line 252, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
      header.header #nav-toggle.active .icon:before, header.header #nav-toggle.active .icon:after {
        top: 0; }
      /* line 255, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
      header.header #nav-toggle.active .icon:before {
        bottom: -8px;
        transform: rotate(45deg); }
      /* line 260, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
      header.header #nav-toggle.active .icon:after {
        transform: rotate(-45deg); }
    /* line 264, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle.active:hover .icon {
      transform: rotate(90deg); }
    /* line 267, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_header.scss */
    header.header #nav-toggle.active .label {
      opacity: 0;
      transition-delay: 0; }

/*--------------------------------------------------------*\
  Footer
\*--------------------------------------------------------*/
/* line 5, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
.footer {
  position: absolute;
  z-index: 3;
  bottom: 0;
  padding: 2.5rem 0; }

/* line 13, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
.copyright {
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
  position: relative;
  display: inline-block; }
  @media (max-width: 31.24em) {
    .copyright {
      text-align: center;
      display: block; } }
  /* line 24, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
  .copyright p, .copyright .menu-footer-container {
    display: inline-block; }
  /* line 27, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
  .copyright .menu-footer-container {
    pointer-events: auto; }
    /* line 29, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
    .copyright .menu-footer-container .menu {
      display: inline-block; }
      /* line 31, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
      .copyright .menu-footer-container .menu li {
        display: inline-block; }
        /* line 33, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
        .copyright .menu-footer-container .menu li a {
          color: black;
          display: inline-block; }
          @media (min-width: 31.25em) {
            /* line 35, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
            .copyright .menu-footer-container .menu li a:hover {
              color: #B0171E !important; } }
          /* line 43, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
          .copyright .menu-footer-container .menu li a::before {
            color: black !important;
            content: ' | '; }

/* line 55, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
.social-media {
  right: 3.5rem;
  position: absolute;
  top: 0;
  pointer-events: auto; }
  @media (max-width: 39.99em) {
    .social-media {
      right: 2rem; } }
  @media (max-width: 29.99em) {
    .social-media {
      right: 1.5rem; } }
  @media (max-width: 31.24em) {
    .social-media {
      text-align: center;
      position: relative;
      right: 0;
      margin-top: 1rem; } }
  /* line 70, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
  .social-media .btn.icon {
    position: relative;
    width: 2rem;
    height: 2rem;
    margin: 0 0 0 .5rem;
    padding: 1rem; }
    /* line 77, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
    .social-media .btn.icon svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 1rem; }
      /* line 82, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
      .social-media .btn.icon svg:hover path {
        fill: #649dc8 !important;
        transition: fill 250ms ease-in-out 500ms; }

/* line 91, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
.about .social-media .btn.icon:hover svg path, .about .social-media .btn.icon svg:hover path {
  fill: white !important; }

/* line 95, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
.about .social-media .btn div[class^=border] {
  background: white !important; }

/* line 99, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_footer.scss */
.about .copyright .menu-footer-container .menu li a:hover {
  color: white !important; }

/*--------------------------------------------------------*\
  Banner
\*--------------------------------------------------------*/
/* line 5, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.block.block-banner {
  height: 32rem;
  max-height: 100%;
  padding: 0; }
  @media (max-width: 31.24em) {
    .block.block-banner {
      height: auto;
      padding-bottom: 0 !important; } }
  /* line 14, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .block.block-banner.main {
    height: 100%; }
    @media (max-width: 31.24em) {
      .block.block-banner.main .slidr-banner-item .bg-image {
        display: none; } }
    @media (max-width: 31.24em) {
      .block.block-banner.main .slidr-banner-item .ctn {
        height: 100%;
        background-color: transparent !important; } }
    /* line 30, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
    .block.block-banner.main .banner-title {
      font-family: 'gunny_rewrittenregular';
      font-size: 4.75em;
      line-height: 1.125; }
  @media (max-width: 31.24em) {
    .block.block-banner:before {
      padding-top: 0 !important; } }
  /* line 39, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .block.block-banner + .block {
    padding: 3.5rem 0 7.5rem !important; }
    @media (max-width: 57.8025em) {
      .block.block-banner + .block {
        padding: 1.5rem 0 3.5rem !important; } }
    @media (max-width: 31.24em) {
      .block.block-banner + .block {
        padding: 0 0 3.5rem !important; } }

/* line 51, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.slidr-banner {
  display: inline-block;
  float: left;
  width: 100% !important;
  height: 100% !important; }

/* line 58, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.slidr-banner-item {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 100%;
  background-size: cover; }
  /* line 66, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .slidr-banner-item .bg-image {
    display: none; }
    @media (max-width: 31.24em) {
      .slidr-banner-item .bg-image {
        display: block; } }
  /* line 73, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .slidr-banner-item video {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); }
    @media (max-width: 48.0525em) {
      .slidr-banner-item video {
        display: none; } }
  /* line 89, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .slidr-banner-item .ctn {
    height: 100%; }
    @media (max-width: 31.24em) {
      .slidr-banner-item .ctn {
        position: relative;
        height: auto;
        padding: 1.5em;
        background-color: #1c2b39 !important; } }
    /* line 100, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
    .slidr-banner-item .ctn .btn {
      color: white !important;
      border-color: white !important; }
      /* line 104, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
      .slidr-banner-item .ctn .btn div[class^=border] {
        background-color: white !important; }
      /* line 107, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
      .slidr-banner-item .ctn .btn:hover {
        border-color: transparent !important; }

@media (max-width: 31.24em) {
  .block.block-banner:not(.main) .slidr-banner {
    position: relative !important; } }

@media (max-width: 31.24em) {
  .block.block-banner:not(.main) .slidr-banner-item {
    background: #1c2b39 !important; } }
@media (max-width: 31.24em) {
  .block.block-banner:not(.main) .slidr-banner-item .slidr-banner {
    position: relative !important; } }
@media (max-width: 31.24em) {
  .block.block-banner:not(.main) .slidr-banner-item .banner-content {
    position: relative;
    width: 100%;
    background-color: #1c2b39 !important; } }
@media (min-width: 31.25em) {
  .block.block-banner:not(.main) .slidr-banner-item .banner-content.top {
    top: 8em; } }
@media (max-width: 31.24em) {
  .block.block-banner:not(.main) .slidr-banner-item .banner-content.middle {
    position: relative;
    top: auto; } }
@media (min-width: 31.25em) {
  .block.block-banner:not(.main) .slidr-banner-item .banner-content.bottom {
    bottom: 0; } }
@media (min-width: 31.25em) {
  .block.block-banner:not(.main) .slidr-banner-item .banner-content.centered {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); } }
@media (min-width: 31.25em) {
  .block.block-banner:not(.main) .slidr-banner-item .banner-content.middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }
@media (min-width: 31.25em) {
  .block.block-banner:not(.main) .slidr-banner-item .banner-content.middle.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

/* line 173, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.block.block-banner.main {
  padding-bottom: 0 !important; }

/* line 179, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.block.block-banner.main .slidr-banner-item .banner-content.top {
  top: 8em; }
/* line 182, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.block.block-banner.main .slidr-banner-item .banner-content.centered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
/* line 185, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.block.block-banner.main .slidr-banner-item .banner-content.middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
  /* line 187, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .block.block-banner.main .slidr-banner-item .banner-content.middle.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
/* line 191, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.block.block-banner.main .slidr-banner-item .banner-content.bottom {
  bottom: 5em; }
/* line 195, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.block.block-banner.main .slidr-banner-item .banner-content.left .btn-ctn {
  text-align: left; }

/* line 202, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.banner-content {
  color: #fff;
  position: absolute;
  z-index: 10;
  width: calc( 100% - 7rem); }
  @media (max-width: 50em) {
    .banner-content {
      font-size: 10px; } }
  @media (max-width: 31.24em) {
    .banner-content {
      font-size: 8px; } }
  /* line 216, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .banner-content.text-dark {
    color: #1c2b39; }
  /* line 219, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .banner-content.centered {
    text-align: center; }
    /* line 221, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
    .banner-content.centered .banner-subtitle {
      float: none;
      margin-right: auto;
      margin-left: auto; }
  /* line 229, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .banner-content.banner-quote:first-child .first-letter {
    position: relative; }
    /* line 231, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
    .banner-content.banner-quote:first-child .first-letter:before {
      position: absolute;
      z-index: 1;
      top: -.5em;
      left: -1em;
      display: block;
      width: 1.4em;
      height: 1.4em;
      background: url('./img/svg/quote-begin-white.svg') no-repeat top left;
      background-size: contain;
      content: '';
      opacity: .25; }
      @media (max-width: 31.24em) {
        .banner-content.banner-quote:first-child .first-letter:before {
          top: -.2em; } }

/* line 256, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.tablet-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-size: cover; }
  @media (min-width: 48.0625em) {
    .tablet-bg-image {
      background-image: url(#); }
      /* line 259, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
      .tablet-bg-image .bg-image {
        visibility: visible; } }
  @media (max-width: 31.24em) {
    .tablet-bg-image {
      background-image: url(#) !important; } }
  /* line 274, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .tablet-bg-image .bg-image {
    visibility: hidden; }

/* line 279, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.mobile-bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-size: cover; }
  @media (min-width: 31.25em) {
    .mobile-bg-image {
      background-image: url(#); }
      /* line 282, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
      .mobile-bg-image .bg-image {
        visibility: visible; } }
  /* line 294, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .mobile-bg-image .bg-image {
    visibility: hidden; }

/* line 299, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.banner-title {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  font-family: 'gunny_rewrittenregular';
  font-size: 5em;
  line-height: 1.125; }

/* line 304, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.banner-subtitle {
  width: 83.19328%;
  float: left;
  margin-right: 0.84034%;
  font-size: 2em;
  line-height: 1.375;
  font-family: 'Frutiger LT W04_45 Light', helvetica, arial; }

/* line 309, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.banner-subtext, .banner-copy, .banner-logo {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  font-size: 1.25em;
  line-height: 1.25;
  font-family: 'Frutiger LT W04_45 Light', helvetica, arial;
  margin-top: 1.5em; }
  /* line 314, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .banner-subtext strong, .banner-copy strong, .banner-logo strong {
    font-family: 'Gotham Rounded A', 'Gotham Rounded B';
    font-weight: 700;
    font-size: 1em;
    line-height: 1;
    font-family: 'Frutiger LT W04_65 Bold', helvetica, arial; }

/* line 320, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.banner-copy p {
  margin: 0 0 .5em; }
/* line 323, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.banner-copy img {
  display: inline-block; }
  @media (max-width: 31.24em) {
    .banner-copy img {
      transform: scale3d(.5, .5, 1); } }

/* line 334, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.home .block-banner .banner-title span {
  opacity: 0;
  transition: opacity 250ms ease-in-out 250ms; }
  /* line 337, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
  .home .block-banner .banner-title span + span {
    transition-delay: 1000ms; }
/* line 341, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.home .block-banner .banner-subtext {
  opacity: 0;
  transition: opacity 250ms ease-in-out 1750ms; }
/* line 348, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_banner.scss */
.home .clean-load .block-banner .banner-title span, .home .clean-load .block-banner .banner-subtext {
  opacity: 1; }

/*--------------------------------------------------------*\
	Block
\*--------------------------------------------------------*/
/* line 5, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.block {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 7.5rem 0; }
  @media (max-width: 47.99em) {
    .block {
      padding: 0 0 3.5rem 0 !important; } }
  /* line 18, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
  .block.no-padding {
    padding: 0; }
  /* line 21, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
  .block.block-graphic {
    z-index: 2;
    pointer-events: none; }

/* line 28, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.block:not([class*='bg']) {
  background-color: transparent; }

/* line 32, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.block-content + .block-content {
  padding-top: 0; }

/* line 36, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.block-header {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1rem; }

/* line 42, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.block-main {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0; }

/* line 46, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.block-main a {
  color: #649dc8; }
  /* line 48, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
  .block-main a:hover {
    color: #8f8f8f; }

/*--------------------------------------------------------*\
	Title + Content + Button
\*--------------------------------------------------------*/
/* line 57, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.title {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  font-size: 2rem;
  line-height: 1.25;
  font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
  text-align: center;
  margin-bottom: .5rem; }

/* line 66, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.cnt {
  width: 66.38655%;
  float: left;
  margin-right: 0.84034%;
  margin-left: 16.80672%;
  margin-right: 16.80672%;
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Frutiger LT W04_45 Light', helvetica, arial;
  text-align: center; }

/* line 74, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.btn-ctn {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  margin-top: 2rem;
  padding: .75rem 0; }

/* line 85, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
.btn {
  font-size: 1rem;
  line-height: 1;
  font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
  color: #1c2b39 !important;
  position: relative;
  display: inline-block;
  padding: .75rem 1.25rem;
  border: 1px solid #1c2b39;
  transition: background-color 500ms ease-in-out 0ms, color 250ms ease-in-out 0ms, border-color 0ms linear 0ms; }
  /* line 98, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
  .btn div[class^=border] {
    position: absolute;
    background-color: #649dc8;
    transition: top 250ms linear, left 250ms linear, bottom 250ms linear, right 250ms linear, height 0ms linear, width 0ms linear; }
    /* line 103, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn div[class^=border].border-top, .btn div[class^=border].border-bottom {
      height: 0; }
    /* line 106, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn div[class^=border].border-left, .btn div[class^=border].border-right {
      width: 0; }
    /* line 109, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn div[class^=border].border-top {
      top: -1px;
      right: 100%;
      left: -1px;
      transition-delay: 0ms;
      transition-duration: 0ms; }
    /* line 117, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn div[class^=border].border-right {
      top: -1px;
      right: -1px;
      bottom: 100%;
      transition-delay: 0ms;
      transition-duration: 0ms; }
    /* line 125, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn div[class^=border].border-bottom {
      right: -1px;
      bottom: -1px;
      left: 100%;
      transition-delay: 0ms;
      transition-duration: 0ms; }
    /* line 133, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn div[class^=border].border-left {
      top: 100%;
      bottom: -1px;
      left: -1px;
      transition-delay: 0ms;
      transition-duration: 0ms; }
  /* line 142, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
  .btn:hover {
    color: #649dc8 !important;
    text-decoration: none !important;
    background-color: transparent;
    border-color: transparent;
    transition: background-color 500ms ease-in-out 1000ms, color 250ms ease-in-out 500ms, border-color 0ms linear 0ms; }
    /* line 151, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn:hover div[class^=border].border-top, .btn:hover div[class^=border].border-bottom {
      height: 1px; }
    /* line 154, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn:hover div[class^=border].border-left, .btn:hover div[class^=border].border-right {
      width: 1px; }
    /* line 157, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn:hover div[class^=border].border-top {
      right: -1px;
      transition: top 250ms linear, left 250ms linear, bottom 250ms linear, right 250ms linear, height 0ms linear, width 0ms linear; }
    /* line 162, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn:hover div[class^=border].border-right {
      bottom: -1px;
      transition: top 125ms linear 250ms, left 125ms linear 250ms, bottom 125ms linear 250ms, right 125ms linear 250ms, height 0ms linear 250ms, width 0ms linear 250ms; }
    /* line 172, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn:hover div[class^=border].border-bottom {
      left: -1px;
      transition: top 250ms linear 375ms, left 250ms linear 375ms, bottom 250ms linear 375ms, right 250ms linear 375ms, height 0ms linear 375ms, width 0ms linear 375ms; }
    /* line 182, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn:hover div[class^=border].border-left {
      top: -1px;
      transition: top 125ms linear 625ms, left 125ms linear 625ms, bottom 125ms linear 625ms, right 125ms linear 625ms, height 0ms linear 625ms, width 0ms linear 625ms; }
  /* line 194, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
  .btn.icon {
    display: inline-block;
    vertical-align: top;
    max-width: 40px;
    margin: 0 5rem 5rem;
    padding: 0; }
    /* line 200, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn.icon svg {
      display: block;
      width: 100%;
      height: 100%; }
    /* line 205, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn.icon .description {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.875rem;
      line-height: 1rem;
      font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
      color: #1c2b39;
      white-space: nowrap;
      position: absolute;
      top: 100%;
      padding-top: 40%; }
    /* line 217, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn.icon .tooltip {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.875rem;
      line-height: 1rem;
      font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
      white-space: nowrap;
      position: absolute;
      top: 125%;
      display: block;
      margin-top: 13px;
      padding: .5rem;
      background: #fff;
      border: 1px solid #1c2b39;
      border-radius: .5rem;
      opacity: 0;
      transition: all 250ms cubic-bezier(.19, 1, .22, 1); }
      /* line 235, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
      .btn.icon .tooltip:after, .btn.icon .tooltip:before {
        position: absolute;
        bottom: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border: solid transparent;
        content: ' ';
        pointer-events: none; }
      /* line 247, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
      .btn.icon .tooltip:after {
        margin-left: -10px;
        border-bottom-color: #fff;
        border-color: rgba(255, 255, 255, 0);
        border-width: 10px; }
      /* line 254, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
      .btn.icon .tooltip:before {
        margin-left: -11px;
        border-bottom-color: #1c2b39;
        border-color: transparent;
        border-width: 11px; }
    /* line 263, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn.icon:hover .tooltip {
      top: 100%;
      opacity: 1; }
    /* line 269, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_block.scss */
    .btn.icon:hover svg path {
      fill: #649dc8 !important;
      transition: fill 250ms ease-in-out 500ms; }

/*--------------------------------------------------------*\
	Locations
\*--------------------------------------------------------*/
/* line 5, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
.block.block-banner + .block.block-location, .block.block-banner ~ .block.block-location {
  padding: 3.5em 0 !important; }
  @media (max-width: 47.99em) {
    .block.block-banner + .block.block-location, .block.block-banner ~ .block.block-location {
      padding: 3.5rem 0 !important; } }

/* line 13, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
.block-location {
  display: inline-block;
  vertical-align: top;
  float: none;
  box-sizing: border-box;
  width: 40%; }
  @media (max-width: 47.99em) {
    .block-location {
      width: 100%; } }
  /* line 23, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
  .block-location .ctn {
    padding: 0 0rem; }
  /* line 26, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
  .block-location + .block-location {
    padding: 3.5em 0; }
  /* line 29, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
  .block-location .btn-ctn {
    text-align: center;
    margin-top: 0; }
  /* line 34, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
  .block-location .btn-ctn a {
    display: inline-block;
    margin: 0 4em 5em; }
  /* line 38, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
  .block-location p {
    text-align: center;
    margin: 0 0 1.5em; }
  /* line 43, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
  .block-location .map-image {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    background: #649dc8; }
    /* line 49, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
    .block-location .map-image img {
      display: block;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      transition: opacity 150ms ease-in-out; }
    /* line 58, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_location.scss */
    .block-location .map-image:hover img {
      opacity: .33; }

/*--------------------------------------------------------*\
	Appearing Content
\*--------------------------------------------------------*/
/* line 6, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_appearing-content.scss */
.block-banner + .to-appear ~ .to-appear .ctn {
  opacity: 0;
  transition: opacity 750ms; }
  /* line 9, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_appearing-content.scss */
  .block-banner + .to-appear ~ .to-appear .ctn.appear {
    opacity: 1; }
  @media (max-width: 31.24em) {
    .block-banner + .to-appear ~ .to-appear .ctn {
      opacity: 1; } }

/* line 20, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_appearing-content.scss */
.block-banner ~ .block-location.to-appear .ctn {
  opacity: 1 !important; }
  /* line 22, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_appearing-content.scss */
  .block-banner ~ .block-location.to-appear .ctn .btn-ctn {
    opacity: 0;
    transition: opacity 750ms; }
    @media (max-width: 31.24em) {
      .block-banner ~ .block-location.to-appear .ctn .btn-ctn {
        opacity: 1; } }
  /* line 30, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_appearing-content.scss */
  .block-banner ~ .block-location.to-appear .ctn.appear .btn-ctn {
    opacity: 1; }

/*--------------------------------------------------------*\
  Items
\*--------------------------------------------------------*/
@media (max-width: 31.24em) {
  .block-sort {
    padding-top: 0; } }
@media (max-width: 31.24em) {
  .block-sort .block-header {
    margin: 0; } }
@media (max-width: 31.24em) {
  .block-sort .block-header .sort {
    display: none; } }
/* line 22, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
.block-sort.sort-loaded .block-main {
  opacity: 1; }
/* line 26, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
.block-sort .block-main {
  opacity: 0;
  transition: opacity 150ms ease-in-out 500ms; }
  @media (max-width: 31.24em) {
    .block-sort .block-main {
      opacity: 1; } }
/* line 34, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
.block-sort .sort-item-mobile-header {
  font-size: 1.125rem;
  line-height: 2.5rem;
  font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
  color: #1c2b39;
  text-align: center;
  display: none;
  margin: 0 -1.5rem;
  border: 1px solid #1c2b39;
  border-width: 1px 0; }
  @media (max-width: 31.24em) {
    .block-sort .sort-item-mobile-header {
      display: block; } }
  @media (max-width: 31.24em) {
    .block-sort .sort-item-mobile-header.active + .sort-item-category-container {
      max-height: 10000px; } }
  /* line 54, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .block-sort .sort-item-mobile-header.active + .sort-item-category-container + .sort-item-mobile-header {
    border-top-width: 1px; }
/* line 60, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
.block-sort .sort-item-category-container {
  position: static;
  display: inline; }
  @media (max-width: 31.24em) {
    .block-sort .sort-item-category-container {
      position: relative;
      display: block;
      overflow: hidden;
      max-height: 0;
      margin: 0 -1.5rem;
      padding: 0 1.5rem;
      transform: translateZ(0); } }
  /* line 77, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .block-sort .sort-item-category-container + .sort-item-mobile-header {
    border-top-width: 0; }

/* line 85, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
.item-popup {
  position: absolute;
  z-index: -1;
  right: 1%;
  left: 1%;
  display: none;
  width: 98%;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0; }
  @media (max-width: 31.24em) {
    .item-popup {
      right: 0;
      left: 0;
      margin-top: 0; } }
  @media (max-width: 31.24em) {
    .item-popup {
      position: relative;
      z-index: 1;
      display: block;
      margin-top: 0;
      margin-top: 0;
      background: #fff;
      opacity: 1; } }
  /* line 114, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .item-popup header {
    position: relative;
    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
    background: url('./img/svg/rule-with-arrow.svg') no-repeat left bottom;
    background-size: 1500px 17px; }
    @media (max-width: 31.24em) {
      .item-popup header {
        display: none; } }
    /* line 127, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
    .item-popup header .name {
      font-family: 'gunny_rewrittenregular';
      font-size: 2rem;
      line-height: 3rem; }
    /* line 130, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
    .item-popup header .close {
      font-size: 1em;
      line-height: 1em;
      font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
      position: relative;
      float: right;
      margin-top: 16px;
      padding: 10px 25px 16px 0;
      cursor: pointer;
      transform-origin: 0, 0;
      transition: all 250ms ease-out; }
      /* line 142, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
      .item-popup header .close .icon, .item-popup header .close .icon:before, .item-popup header .close .icon:after {
        position: absolute;
        top: 0;
        display: block;
        width: 25px;
        height: 2px;
        background: black;
        border-radius: 1px;
        content: '';
        cursor: pointer;
        transition: all 250ms ease-out; }
      /* line 156, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
      .item-popup header .close .icon {
        background-color: transparent; }
      /* line 159, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
      .item-popup header .close .icon:before {
        bottom: -8px;
        transform: rotate(45deg); }
      /* line 164, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
      .item-popup header .close .icon:after {
        bottom: -8px;
        transform: rotate(-45deg); }
      /* line 169, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
      .item-popup header .close:hover .icon {
        transform: rotate(90deg); }
  /* line 174, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .item-popup p {
    margin: 0 0 2em 0; }
  /* line 177, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .item-popup h3 {
    font-size: 1.25em;
    line-height: 2.5rem;
    font-family: 'Frutiger LT W04_55 Roman', helvetica, arial; }
  /* line 180, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .item-popup article, .item-popup aside {
    display: inline-block;
    vertical-align: top; }
  /* line 184, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .item-popup article {
    font-size: 1em;
    line-height: 1.5rem;
    font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
    position: relative;
    width: 100%;
    max-width: 1072px; }
    @media (max-width: 31.24em) {
      .item-popup article {
        font-size: 0.875em;
        line-height: 1.25rem;
        font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
        width: 100%;
        margin-right: 0; } }
    /* line 197, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
    .item-popup article.has-aside {
      width: 54.10448%;
      max-width: 580px;
      margin-right: 13.52612%; }
      @media (max-width: 31.24em) {
        .item-popup article.has-aside {
          width: 100%;
          margin-right: 0; } }
    /* line 207, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
    .item-popup article .btn-ctn {
      text-align: left; }
  /* line 216, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_items.scss */
  .item-popup aside {
    color: #1c2b39;
    width: 31.71642%; }
    @media (max-width: 31.24em) {
      .item-popup aside {
        display: none; } }

/*--------------------------------------------------------*\
  Team
\*--------------------------------------------------------*/
/* line 11, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
.block-team .block-main {
  position: relative; }
  /* line 14, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .block-team .block-main.open .team-item-name {
    opacity: 0; }
  /* line 17, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .block-team .block-main.open .team-item:not(.open) {
    opacity: 0;
    pointer-events: none;
    transition: all 250ms ease-in-out; }
    @media (max-width: 31.24em) {
      .block-team .block-main.open .team-item:not(.open) {
        transition: none; } }
  /* line 26, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .block-team .block-main.open .team-item:not(.open) canvas {
    opacity: 0; }
    /* line 28, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .block-team .block-main.open .team-item:not(.open) canvas.blue {
      opacity: 0;
      transition: all 250ms ease-in-out; }
      @media (max-width: 31.24em) {
        .block-team .block-main.open .team-item:not(.open) canvas.blue {
          transition: none; } }

/* line 48, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
.block-header .sort {
  font-size: 1em;
  line-height: 1.25em;
  font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
  text-align: center; }
  /* line 52, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .block-header .sort a {
    color: #1c2b39; }
    /* line 54, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .block-header .sort a:hover {
      color: #B0171E; }
  /* line 58, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .block-header .sort a ~ a {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid #1c2b39; }
  /* line 64, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .block-header .sort.active {
    border-left-color: rgba(28, 43, 57, 0.3); }
    /* line 66, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .block-header .sort.active a:not(.active) {
      color: rgba(28, 43, 57, 0.3); }
      /* line 68, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
      .block-header .sort.active a:not(.active):hover {
        color: #B0171E; }

/* line 77, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
.team-item {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 31.3%;
  max-width: 31.3%;
  margin-top: 2%;
  margin-right: 1%;
  margin-left: 1%;
  transform: translateZ(0);
  transform-origin: 0 50%;
  transition: max-width 250ms cubic-bezier(.19, 1, .22, 1), width 500ms ease-in-out, transform 500ms ease-in-out, max-height 250ms cubic-bezier(.19, 1, .22, 1), height 250ms cubic-bezier(.19, 1, .22, 1); }
  @media (max-width: 49.99em) {
    .team-item {
      width: 48%;
      max-width: 48%; } }
  @media (max-width: 31.24em) {
    .team-item {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      margin-top: 0;
      margin-right: auto;
      margin-left: auto;
      transition: none; } }
  /* line 108, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-item.hide {
    overflow: hidden;
    width: 0;
    max-width: 0;
    margin-right: 0;
    margin-left: 0;
    transform: translateZ(0); }
    @media (max-width: 31.24em) {
      .team-item.hide {
        width: 100%;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto; } }
    /* line 123, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .team-item.hide + .item-popup {
      display: block;
      height: 0;
      margin-top: 0;
      opacity: 0;
      transform-origin: 0 50%;
      transition: all 250ms ease-in-out, display 0; }
      @media (max-width: 31.24em) {
        .team-item.hide + .item-popup {
          transition: none; } }
  /* line 137, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-item .team-item-name {
    font-size: 1.25rem;
    line-height: 2rem;
    font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
    white-space: nowrap; }
  /* line 142, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-item.open + .item-popup {
    z-index: 10;
    display: block;
    opacity: 1;
    transition: all 250ms ease-in-out, display 0; }
    @media (max-width: 31.24em) {
      .team-item.open + .item-popup {
        transition: none; } }
  /* line 154, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-item > img {
    position: absolute;
    z-index: -1;
    top: 0;
    opacity: 0; }
  /* line 161, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-item canvas {
    display: block;
    width: auto;
    max-width: 100%;
    cursor: pointer; }
    @media (max-width: 31.24em) {
      .team-item canvas {
        display: block;
        width: calc(100% + 3rem);
        max-width: 10000%;
        margin: 0 -1.5rem .5rem; } }
    /* line 174, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .team-item canvas.blue {
      transition-delay: 250ms;
      transition-duration: 0; }
      @media (max-width: 31.24em) {
        .team-item canvas.blue {
          display: none; } }
    /* line 182, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .team-item canvas.grey {
      position: absolute;
      top: 0;
      transition: all 250ms ease-in-out; }
      @media (max-width: 31.24em) {
        .team-item canvas.grey {
          position: relative;
          transition-duration: 0ms; } }
  /* line 196, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-item:hover .grey, .team-item.open .grey {
    opacity: 0; }
    @media (max-width: 31.24em) {
      .team-item:hover .grey, .team-item.open .grey {
        opacity: 1; } }
  /* line 203, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-item:hover .blue, .team-item.open .blue {
    transition: all 250ms ease-in-out; }

/* line 209, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
.team-popup {
  margin-top: -2rem; }
  @media (max-width: 31.24em) {
    .team-popup {
      margin-top: 0; } }
  /* line 216, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-popup header .job-title {
    font-size: 1.25rem;
    line-height: 3rem;
    font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
    margin-left: .5rem;
    padding-left: .5rem;
    border-left: 2px solid #1c2b39; }
  /* line 226, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-popup article .social {
    position: relative;
    right: auto; }
    @media (max-width: 31.24em) {
      .team-popup article .social {
        display: none; } }
    /* line 233, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .team-popup article .social .btn {
      position: relative;
      width: 2rem;
      height: 2rem;
      margin: 0 .5rem 0 0;
      padding: 1rem; }
      /* line 240, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
      .team-popup article .social .btn svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 1rem; }
        /* line 244, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
        .team-popup article .social .btn svg path {
          fill: #1c2b39; }
  /* line 251, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
  .team-popup aside {
    text-align: center; }
    /* line 253, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .team-popup aside ul {
      margin-bottom: 2rem; }
    /* line 256, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
    .team-popup aside li {
      position: relative;
      z-index: 100;
      display: block;
      margin: 1.25rem 0; }
      /* line 262, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
      .team-popup aside li a {
        display: block;
        max-height: 2.5em;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: grayscale(100%);
        transition: filter 250ms; }
        /* line 271, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
        .team-popup aside li a img {
          visibility: hidden;
          height: 70%; }
        /* line 275, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_team.scss */
        .team-popup aside li a:hover {
          filter: grayscale(0%); }

/*--------------------------------------------------------*\
  Portfolio
\*--------------------------------------------------------*/
/* line 9, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
.block-portfolio .block-main {
  position: relative; }
  /* line 12, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .block-portfolio .block-main.open .sort-item:not(.open) {
    opacity: 0;
    pointer-events: none;
    transition: all 250ms ease-in-out; }
    @media (max-width: 31.24em) {
      .block-portfolio .block-main.open .sort-item:not(.open) {
        opacity: 1;
        pointer-events: all; } }
  /* line 21, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .block-portfolio .block-main.open .sort-item:not(.open) img {
    opacity: 0; }
    @media (max-width: 31.24em) {
      .block-portfolio .block-main.open .sort-item:not(.open) img {
        opacity: 1; } }

/* line 30, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
.portfolio-item {
  text-align: center;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 23%;
  max-width: 23%;
  min-height: 6em;
  margin-top: 2%;
  margin-right: 1%;
  margin-left: 1%;
  transform: scale3d(1, 1, 1);
  transform-origin: 0 50%;
  transition: max-width 250ms cubic-bezier(.19, 1, .22, 1), width 500ms ease-in-out, transform 500ms ease-in-out; }
  @media (min-width: 64em) {
    /* line 32, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item.cols-4-1.open .carret {
      left: 0; }
    /* line 35, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item.cols-4-2.open .carret {
      left: -110%; }
    /* line 38, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item.cols-4-3.open .carret {
      left: -218%; }
    /* line 41, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item.cols-4-4.open .carret {
      left: -328%; } }
  @media (min-width: 50em) and (max-width: 63.99em) {
    .portfolio-item {
      width: 31.3%;
      max-width: 31.3%; }
      /* line 48, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
      .portfolio-item.cols-3-1.open .carret {
        left: 0;
        width: 312%; }
      /* line 53, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
      .portfolio-item.cols-3-2.open .carret {
        left: -106%;
        width: 312%; }
      /* line 58, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
      .portfolio-item.cols-3-3.open .carret {
        left: -212%;
        width: 312%; } }
  @media (min-width: 31.25em) and (max-width: 49.99em) {
    .portfolio-item {
      width: 48%;
      max-width: 48%; }
      /* line 67, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
      .portfolio-item.cols-3-1.open .carret {
        left: 0;
        width: 203%; }
      /* line 72, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
      .portfolio-item.cols-3-2.open .carret {
        left: -104%;
        width: 203%; } }
  @media (max-width: 31.24em) {
    .portfolio-item {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 6em;
      margin-right: auto;
      margin-left: auto;
      transition: none; } }
  /* line 109, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item a {
    display: block;
    width: 100%;
    min-height: 6rem;
    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity 150ms cubic-bezier(.19, 1, .22, 1); }
  /* line 119, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item:hover a {
    opacity: .5; }
  /* line 125, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item:hover .carret:after, .portfolio-item:hover .carret:before {
    transform: translate(0, -1em); }
  /* line 130, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item.open {
    z-index: 11; }
    /* line 132, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item.open .carret {
      left: 0;
      overflow: visible;
      width: 426%;
      border-top: 1px solid black;
      transform: translateY(2.75em);
      transition-duration: 250ms; }
      /* line 141, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
      .portfolio-item.open .carret:after, .portfolio-item.open .carret:before {
        transform: translate(2em, -1em); }
  /* line 146, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item .carret {
    position: absolute;
    z-index: 11;
    bottom: 0;
    left: 50%;
    overflow: hidden;
    width: 1.83333em;
    height: 1em;
    pointer-events: none;
    transform: translateY(0);
    transition: width 250ms ease-in-out, transform 250ms ease-in-out, left 250ms ease-in-out;
    transition-duration: 0ms; }
    @media (max-width: 31.24em) {
      .portfolio-item .carret {
        display: none; } }
    /* line 163, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item .carret:after, .portfolio-item .carret:before {
      position: absolute;
      z-index: 12px;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border: solid transparent;
      content: ' ';
      pointer-events: none;
      transform: translate(0, -2em);
      transition: left 250ms ease-in-out, transform 150ms ease-in-out; }
    /* line 178, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item .carret:after {
      margin-left: 0;
      border-color: rgba(143, 143, 143, 0);
      border-top-color: white;
      border-width: .83333em; }
    /* line 185, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item .carret:before {
      margin-left: -1px;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: black;
      border-width: calc(.83333333em + 1px); }
  /* line 193, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item.hide, .portfolio-item.repeated-item {
    overflow: hidden;
    width: 0;
    max-width: 0;
    height: 0;
    min-height: 0;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    transform: scale3d(1, 1, 1); }
    @media (max-width: 31.24em) {
      .portfolio-item.hide, .portfolio-item.repeated-item {
        width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 6em;
        margin-right: auto;
        margin-left: auto; } }
  /* line 214, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item img {
    display: inline;
    display: none;
    cursor: pointer;
    transition: all 250ms ease-in-out; }
  /* line 221, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item.open + .item-popup {
    z-index: 10;
    display: block;
    opacity: 1;
    transition: all 250ms ease-in-out, display 0; }
    @media (max-width: 31.24em) {
      .portfolio-item.open + .item-popup {
        max-height: 1000px;
        opacity: 1; } }
  /* line 235, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
  .portfolio-item + .item-popup {
    margin-top: -1em; }
    @media (max-width: 31.24em) {
      .portfolio-item + .item-popup {
        font-size: .75em;
        max-height: 0;
        overflow: hidden;
        opacity: 0; } }
    /* line 245, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item + .item-popup header {
      height: 4em;
      background-image: url('#'); }
    /* line 250, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item + .item-popup article {
      position: relative;
      padding: 1em 4.5em; }
      @media (max-width: 49.99em) {
        .portfolio-item + .item-popup article {
          font-size: .75em; } }
      @media (max-width: 31.24em) {
        .portfolio-item + .item-popup article {
          padding: 1em; } }
      /* line 261, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
      .portfolio-item + .item-popup article p {
        font-size: 1.5em;
        line-height: 2em;
        font-family: 'Frutiger LT W04_55 Roman', helvetica, arial;
        position: relative;
        z-index: 2;
        display: block; }
        /* line 269, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
        .portfolio-item + .item-popup article p:first-child .first-letter {
          position: relative; }
          /* line 271, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
          .portfolio-item + .item-popup article p:first-child .first-letter:before {
            position: absolute;
            z-index: 1;
            top: -2.25em;
            left: -3em;
            display: block;
            width: 3.4em;
            height: 3.4em;
            background: url('./img/svg/quote-begin.svg') no-repeat top left;
            background-size: contain;
            content: '';
            opacity: .125; }
    /* line 290, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item + .item-popup .name {
      font-family: 'Gotham Rounded A', 'Gotham Rounded B';
      font-weight: 700;
      font-size: 1.125em;
      line-height: 1.125em;
      font-family: 'Frutiger LT W04_65 Bold', helvetica, arial;
      color: #649dc8; }
    /* line 295, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_portfolio.scss */
    .portfolio-item + .item-popup .job-title {
      font-size: 1.125em;
      line-height: 1.125em;
      font-family: 'Frutiger LT W04_45 Light', helvetica, arial;
      color: #649dc8; }
    @media (max-width: 31.24em) {
      .portfolio-item + .item-popup .btn {
        font-size: 1.25em; } }

/*--------------------------------------------------------*\
  News
\*--------------------------------------------------------*/
/* line 6, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
.news-item {
  position: relative;
  font-size: 1em;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 33.3%;
  max-width: 33.3%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  transform: scale3d(1, 1, 1);
  transform-origin: 0 50%;
  transition: max-width 250ms cubic-bezier(.19, 1, .22, 1), width 500ms ease-in-out, transform 500ms ease-in-out; }
  /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
  .news-item:before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: ''; }
  /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
  .news-item > .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  @media (max-width: 62.5em) {
    .news-item {
      font-size: .75em; } }
  @media (max-width: 50em) {
    .news-item {
      width: 50%;
      max-width: 50%; }
      /* line 14, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
      .news-item:nth-child(10n), .news-item:nth-child(10n-6) {
        position: relative;
        width: 50%;
        max-width: 50%; }
        /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
        .news-item:nth-child(10n):before, .news-item:nth-child(10n-6):before {
          display: block;
          width: 100%;
          padding-top: 100%;
          content: ''; }
        /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
        .news-item:nth-child(10n) > .content, .news-item:nth-child(10n-6) > .content {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0; }
      /* line 20, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
      .news-item:nth-child(2n) {
        background: #fff; }
      /* line 23, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
      .news-item:nth-child(4n-2), .news-item:nth-child(4n-1) {
        background: #fbfbfb; } }
  @media (max-width: 31.24em) {
    .news-item {
      position: relative;
      width: calc(100% + 3rem) !important;
      max-width: 200% !important;
      margin: 0 -1.5rem;
      background: #fff !important; }
      /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
      .news-item:before {
        display: block;
        width: 100%;
        padding-top: 50%;
        content: ''; }
      /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
      .news-item > .content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
      /* line 35, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
      .news-item:nth-child(10n), .news-item:nth-child(10n-6) {
        position: relative; }
        /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
        .news-item:nth-child(10n):before, .news-item:nth-child(10n-6):before {
          display: block;
          width: 100%;
          padding-top: 50%;
          content: ''; }
        /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
        .news-item:nth-child(10n) > .content, .news-item:nth-child(10n-6) > .content {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0; }
      /* line 38, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
      .news-item:nth-child(2n) {
        background: #fbfbfb !important; } }
  /* line 60, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item.blog a {
    color: #00a5b8; }
    /* line 62, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
    .news-item.blog a:hover {
      color: #8f8f8f; }
  /* line 68, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item.news a {
    color: #B0171E; }
    /* line 70, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
    .news-item.news a:hover {
      color: #8f8f8f; }
  /* line 75, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item.instagram {
    color: white; }
    /* line 77, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
    .news-item.instagram a {
      color: white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      padding: 2.5em; }
  /* line 90, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item .background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center center;
    background-repeat: none;
    background-size: cover;
    opacity: 1;
    transition: opacity 150ms; }
  /* line 104, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item .content {
    vertical-align: bottom;
    padding: 2.5em; }
  /* line 108, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item .news-item-date {
    font-family: 'Gotham Rounded A', 'Gotham Rounded B';
    font-weight: 700;
    font-size: 0.875em;
    line-height: 1em;
    font-family: 'Frutiger LT W04_65 Bold', helvetica, arial;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2em; }
  /* line 115, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item .news-item-author {
    font-family: 'Gotham Rounded A', 'Gotham Rounded B';
    font-weight: 700;
    font-size: 0.875em;
    line-height: 1em;
    font-family: 'Frutiger LT W04_65 Bold', helvetica, arial;
    display: inline-block;
    vertical-align: bottom;
    margin-top: 2em; }
  /* line 122, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: .5em;
    margin-bottom: 2em; }
    /* line 127, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
    .news-item .icon svg {
      display: inline-block;
      width: 100%;
      max-width: 2em; }
    /* line 133, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
    .news-item .icon.twitter svg * {
      fill: #649dc8; }
  /* line 138, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item p {
    font-size: 1.25em;
    line-height: 1.5em;
    font-family: 'Frutiger LT W04_55 Roman', helvetica, arial; }
  /* line 141, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item:nth-child(2n) {
    background: #fbfbfb; }
  /* line 145, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item:nth-child(10n), .news-item:nth-child(10n-6) {
    position: relative;
    width: 66.67%;
    max-width: 66.67%; }
    /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
    .news-item:nth-child(10n):before, .news-item:nth-child(10n-6):before {
      display: block;
      width: 100%;
      padding-top: 50%;
      content: ''; }
    /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
    .news-item:nth-child(10n) > .content, .news-item:nth-child(10n-6) > .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  /* line 151, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item.hide {
    overflow: hidden;
    width: 0 !important;
    max-width: 0 !important;
    margin-right: 0;
    margin-left: 0;
    transform: scale3d(0, 0, 0); }
  /* line 160, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
  .news-item img {
    display: inline; }

/* line 165, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
.block-news .block-header .sort {
  margin-bottom: 1.5em; }
  @media (max-width: 31.24em) {
    .block-news .block-header .sort {
      display: block; } }

@media (max-width: 50em) {
  .block-news.sorted .news-item {
    width: 50%;
    max-width: 50%; }
    /* line 178, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
    .block-news.sorted .news-item:nth-child(10n), .block-news.sorted .news-item:nth-child(10n-6) {
      width: 50%;
      max-width: 50%; } }
@media (max-width: 31.24em) {
  .block-news.sorted .news-item {
    position: relative;
    width: calc(100% + 3rem) !important;
    max-width: 200% !important; }
    /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
    .block-news.sorted .news-item:before {
      display: block;
      width: 100%;
      padding-top: 50%;
      content: ''; }
    /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
    .block-news.sorted .news-item > .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    /* line 188, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
    .block-news.sorted .news-item:nth-child(10n), .block-news.sorted .news-item:nth-child(10n-6) {
      position: relative; }
      /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
      .block-news.sorted .news-item:nth-child(10n):before, .block-news.sorted .news-item:nth-child(10n-6):before {
        display: block;
        width: 100%;
        padding-top: 50%;
        content: ''; }
      /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
      .block-news.sorted .news-item:nth-child(10n) > .content, .block-news.sorted .news-item:nth-child(10n-6) > .content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; } }
/* line 192, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
.block-news.sorted .news-item.hide {
  width: 0 !important; }
/* line 195, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_news.scss */
.block-news.sorted .news-item:nth-child(10n), .block-news.sorted .news-item:nth-child(10n-6) {
  position: relative;
  width: 33.3%;
  max-width: 33.3%; }
  /* line 76, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
  .block-news.sorted .news-item:nth-child(10n):before, .block-news.sorted .news-item:nth-child(10n-6):before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: ''; }
  /* line 83, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/mains/_mixins.scss */
  .block-news.sorted .news-item:nth-child(10n) > .content, .block-news.sorted .news-item:nth-child(10n-6) > .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

/*--------------------------------------------------------*\
  Graphic
\*--------------------------------------------------------*/
/* line 5, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_graphic.scss */
.block.block-graphic {
  z-index: 1;
  margin-top: -12rem;
  margin-bottom: -3px;
  padding: 0 !important;
  background-color: transparent; }
  @media (max-width: 31.24em) {
    .block.block-graphic {
      margin-top: -4rem; } }
  /* line 18, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_graphic.scss */
  .block.block-graphic > svg * {
    fill: #f2f2f3; }
  /* line 24, /Users/steve/Work/kbd/ignition/cnt/themes/ignition/assets/styl/src/site/_graphic.scss */
  .block.block-graphic.about > svg * {
    fill: #649dc8; }

/*--------------------------------------------------------*\
   Libs
\*--------------------------------------------------------*/
