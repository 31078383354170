/*--------------------------------------------------------*\
  Portfolio
\*--------------------------------------------------------*/

// Settings
$animation-duration: 250ms;
$animation-easing: ease-in-out;

.block-portfolio .block-main {
  position: relative;
  &.open {
    .sort-item:not(.open) {
      opacity: 0;
      pointer-events: none;
      transition: all $animation-duration $animation-easing;
      @include mq($until: mobile) {
        opacity: 1;
        pointer-events: all;
      }
    }
    .sort-item:not(.open) img {
      opacity: 0;
      @include mq($until: mobile) {
        opacity: 1;
      }
    }
  }
}

.portfolio-item {
  @include mq($from: at-1024) {
    &.cols-4-1.open .carret {
      left: 0;
    }
    &.cols-4-2.open .carret {
      left: -110%;
    }
    &.cols-4-3.open .carret {
      left: -218%;
    }
    &.cols-4-4.open .carret {
      left: -328%;
    }
  }
  @include mq($from: at-800, $until: at-1024) {
    width: 31.3%;
    max-width: 31.3%;
    &.cols-3-1.open .carret {
      left: 0;

      width: 312%;
    }
    &.cols-3-2.open .carret {
      left: -106%;

      width: 312%;
    }
    &.cols-3-3.open .carret {
      left: -212%;

      width: 312%;
    }
  }
  @include mq($from: mobile, $until: at-800) {
    width: 48%;
    max-width: 48%;
    &.cols-3-1.open .carret {
      left: 0;

      width: 203%;
    }
    &.cols-3-2.open .carret {
      left: -104%;

      width: 203%;
    }
  }
  @include mq($until: mobile) {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 6em;
    margin-right: auto;
    margin-left: auto;

    transition: none;


  }

  text-align: center;

  position: relative;

  display: inline-block;
  vertical-align: middle;
  // Columns Size
  width: 23%;
  max-width: 23%;
  min-height: 6em;
  margin-top: 2%;
  margin-right: 1%;
  margin-left: 1%;

  transform: scale3d(1,1,1);
  transform-origin: 0 50%;
  transition: max-width 250ms $timing, width 500ms ease-in-out, transform 500ms ease-in-out;
  a {
    display: block;
    width: 100%;
    min-height: 6rem;

    background-position: center center;
    background-repeat: no-repeat;
    transition: opacity 150ms $timing;
  }
  &:hover {
    a {
      opacity: .5;
    }
  }
  &:hover {
    .carret {
      &:after, &:before {
        transform: translate(0, -1em);
      }
    }
  }
  &.open {
    z-index: 11;
    .carret {
      left: 0;

      overflow: visible;
      width: 426%;

      border-top: 1px solid black;
      transform: translateY(2.75em);
      transition-duration: 250ms;
      &:after, &:before {
        transform: translate(2em, -1em);
      }
    }
  }
  .carret {
    @include mq($until: mobile) {
      display: none;
    }
    position: absolute;
    z-index: 11;
    bottom: 0;
    left: 50%;

    overflow: hidden;
    width: 1.83333em;
    height: 1em;

    pointer-events: none;
    transform: translateY(0);
    transition: width 250ms ease-in-out, transform 250ms ease-in-out, left 250ms ease-in-out;
    transition-duration: 0ms;
    &:after, &:before {
      position: absolute;
      z-index: 12px;
      top: 100%;
      left: 0;

      width: 0;
      height: 0;

      border: solid transparent;
      content: ' ';
      pointer-events: none;
      transform: translate(0, -2em);
      transition: left 250ms ease-in-out, transform 150ms ease-in-out;
    }
    &:after {
      margin-left: 0;

      border-color: rgba($grey, 0);
      border-top-color: white;
      border-width: .83333333em;
    }
    &:before {
      margin-left: -1px;

      border-color: rgba(white, 0);
      border-top-color: black;
      border-width: calc(.83333333em + 1px);
    }
  }
  &.hide, &.repeated-item {
    @include mq($until: mobile) {
      width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 6em;
      margin-right: auto;
      margin-left: auto;
    }

    overflow: hidden;
    width: 0;
    max-width: 0;
    height: 0;
    min-height: 0;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;

    transform: scale3d(1,1,1);
  }
  img {
    display: inline;
    display: none;

    cursor: pointer;
    transition: all $animation-duration $animation-easing;
  }
  &.open + .item-popup {
    z-index: 10;

    display: block;

    opacity: 1;
    transition: all $animation-duration $animation-easing, display 0;
    @include mq($until: mobile) {


      max-height: 1000px;
      opacity: 1;
    }
  }
  & + .item-popup {
    @include mq($until: mobile) {
      font-size: .75em;

      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }

    margin-top: -1em;
    header {
      height: 4em;

      background-image: url('#');
    }
    article {
      @include mq($until: at-800) {
        font-size: .75em;
      }
      @include mq($until: mobile) {
        padding: 1em;
      }

      position: relative;

      padding: 1em 4.5em;
      p {
        @include rg(1.5em, 2em);

        position: relative;
        z-index: 2;

        display: block;
        &:first-child {
          .first-letter {
            position: relative;
            &:before {
              position: absolute;
              z-index: 1;
              top: -2.25em;
              left: -3em;

              display: block;
              width: 3.4em;
              height: 3.4em;

              background: url('./img/svg/quote-begin.svg') no-repeat top left;
              background-size: contain;
              content: '';
              opacity: .125;
            }
          }
        }
      }
    }
    .name {
      @include bd(1.125em, 1.125em);

      color: $blue;
    }
    .job-title {
      @include lt(1.125em, 1.125em);

      color: $blue;
    }
    .btn {
      @include mq($until: mobile) {
        font-size: 1.25em;
      }
    }
  }
}
