/*--------------------------------------------------------*\
	Colors
\*--------------------------------------------------------*/

$blue-dk: #1c2b39;
$blue: #649dc8;
$teal: #00a5b8;
$red: #B0171E;
$grey: #8f8f8f;

$body: $blue-dk;
$hover-color: $red;
$body-link-color: $blue;

$white80: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP4fwYAAs0BzF1VEXcAAAAASUVORK5CYII=");