/*--------------------------------------------------------*\
  Header
\*--------------------------------------------------------*/

header.header {
  @include mq($until: mobile-nav) {
    overflow: visible;
  }

  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;

  overflow: hidden;
  height: 56px;
  .logo {
    position: relative;

    float: left;
    width: 93px;
    max-width: 213px;
    height: 56px;

    background: url('./img/ignition-logo-2x.png') no-repeat center center;
    background-size: cover;
    img {
      display: none;
      width: auto;
      max-width: 100%;
    }
    &:before, &:after {
      @include mq($until: mobile-nav) {
      }

      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;

      width: 10000%;

      background-image: $white80;
      content: '';
      pointer-events: none;
    }
    &:after {
      @include mq($until: mobile-nav) {
        right: 0;

        width: calc(100vw - 93px - 3.5rem);
      }
      @include mq($until: at-640) {
        width: calc(100vw - 93px - 2rem);
      }
      @include mq($until: at-480) {
        width: calc(100vw - 93px - 1.5rem);
      }

      right: 10000%;
      left: 100%;
    }
    a {
      display: block;
      height: 56px;
    }
    .motto {
      @include v-center();

      position: absolute;
      z-index: 200;
      left: 105%;

      overflow: hidden;
      width: 125%;
      margin-top: 2px;
      svg {
        transform: translate(-100%, 125%);
        transition: all 250ms $timing;
      }
    }
    &:hover {
      .motto {
        svg {
          transform: translate(0, 125%);
        }
      }
    }
  }
  nav.nav {
    @include rg(1em, 1em);
    @include mq($until: mobile-nav) {
      position: absolute;
      top: 56px;
      right: 0;
      left: 0;

      float: none;
      height: auto;
      margin-top: 0;

      pointer-events: none;
    }

    position: relative;

    float: right;
    margin-top: -10em;
    .menu-main-navigation-container {
      @include mq($until: mobile-nav) {
        overflow: hidden;
      }
    }
    ul {
      @include mq($until: mobile-nav) {
        border-top: 1px solid #fff;
        transform: translate3d(0, -100%, 0);
        transition: transform 250ms ease-out;
      }
    }
    li {
      @include mq($until: mobile-nav) {
        text-align: center;

        float: none;
        margin: 0;

        background: $white80;
        border-bottom: 1px solid #fff;
        opacity: 1;
        transform: translateX(0);
      }

      float: left;
      margin-right: 3em;

      opacity: 0;
      transform: translateX(3em);
      a {
        @include mq($until: mobile-nav) {
          padding: 1em;
        }

        color: black;

        display: block;

        transition: all 100ms;
        &:hover {
          color: $hover-color;
        }
      }
      &.current_page_item a {
        color: $hover-color;
      }
    }
  }
  #nav-toggle.active + nav.nav {
    @include mq($until: mobile-nav) {
      margin-top: 0;

      pointer-events: all;
    }

    margin-top: 20px;

    transition-delay: 0;
    li {
      opacity: 1;
      transform: translateX(0);
      transition: all 250ms cubic-bezier(.175, .885, .345, 1.480) 50ms;
      & + li {
        transition-delay: 100ms;
        & + li {
          transition-delay: 150ms;
          & + li {
            transition-delay: 190ms;
            & + li {
              transition-delay: 230ms;
            }
          }
        }
      }
    }
    ul {
      @include mq($until: mobile-nav) {
        transform: translate3d(0, 0, 0);
        transition-delay: 0ms !important;
        transition-timing-function: ease-in-out !important;
      }
    }
  }
  #nav-toggle {
    @include rg(1em, 1em);

    position: relative;

    float: right;
    margin-top: 16px;
    padding: 10px 35px 16px 0;

    cursor: pointer;
    transition: all 250ms ease-out;
    .icon, .icon:before, .icon:after {
      position: absolute;

      display: block;
      width: 25px;
      height: 2px;

      background: black;
      border-radius: 1px;
      content: '';
      cursor: pointer;
      transition: all 250ms ease-out;
    }
    .icon {
      &:before {
        top: -8px;
      }
      &:after {
        bottom: -8px;
      }
    }
    .label {
      @include v-center;

      color: black;

      position: absolute;
      right: calc(100% + 10px);

      visibility: hidden;
      margin-top: -2px;

      transition: all 150ms ease-out 150ms;
    }
    &:hover {
      .icon {
        &:before {
          top: -6px;
        }
        &:after {
          bottom: -6px;
        }
      }
    }
    &.active {
      .icon {
        background-color: transparent;
        &:before, &:after {
          top: 0;
        }
        &:before {
          bottom: -8px;

          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
      &:hover .icon {
        transform: rotate(90deg);
      }
      .label {
        opacity: 0;
        transition-delay: 0;
      }
    }
  }
}
