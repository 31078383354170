/*--------------------------------------------------------*\
  News
\*--------------------------------------------------------*/


.news-item {
  @include aspect-ratio(1, 1);
  @include mq($until: 1000px) {
    font-size: .75em;
  }
  @include mq($until: 800px) {
    width: 50%;
    max-width: 50%;
    &:nth-child(10n), &:nth-child(10n-6) {
      @include aspect-ratio(1, 1);

      width: 50%;
      max-width: 50%;
    }
    &:nth-child(2n) {
      background: #fff;
    }
    &:nth-child(4n-2), &:nth-child(4n-1) {
      background: #fbfbfb;
    }
  }
  @include mq($until: mobile) {
    @include aspect-ratio(2, 1);

    width: calc(100% + 3rem) !important;
    max-width: 200% !important;
    margin: 0 -1.5rem;

    background: #fff !important;
    &:nth-child(10n), &:nth-child(10n-6) {
      @include aspect-ratio(2, 1);
    }
    &:nth-child(2n) {
      background: #fbfbfb !important;
    }
  }

  font-size: 1em;

  position: relative;

  display: inline-block;
  vertical-align: middle;
  // Columns Size
  width: 33.3%;
  max-width: 33.3%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;

  transform: scale3d(1,1,1);
  transform-origin: 0 50%;
  transition: max-width 250ms $timing, width 500ms ease-in-out, transform 500ms ease-in-out;
  &.blog {
    a {
      color: $teal;
      &:hover {
        color: $grey;
      }
    }
  }
  &.news {
    a {
      color: $red;
      &:hover {
        color: $grey;
      }
    }
  }
  &.instagram {
    color: white;
    a {
      color: white;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      padding: 2.5em;
    }
  }
  .background {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-position: center center;
    background-repeat: none;
    background-size: cover;
    opacity: 1;
    transition: opacity 150ms;
  }
  .content {
    vertical-align: bottom;
    padding: 2.5em;
  }
  .news-item-date {
    @include bd(.875em, 1em);

    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2em;
  }
  .news-item-author {
    @include bd(.875em, 1em);

    display: inline-block;
    vertical-align: bottom;
    margin-top: 2em;
  }
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: .5em;
    margin-bottom: 2em;
    svg {
      display: inline-block;
      width: 100%;
      max-width: 2em;
    }
    &.twitter {
      svg * {
        fill: #649dc8;
      }
    }
  }
  p {
    @include rg(1.25em, 1.5em);
  }
  &:nth-child(2n) {
    background: #fbfbfb;
  }
  // 4, 10, 14, 20, 
  &:nth-child(10n), &:nth-child(10n-6) {
    @include aspect-ratio(2, 1);

    width: 66.67%;
    max-width: 66.67%;
  }
  &.hide {
    overflow: hidden;
    width: 0 !important;
    max-width: 0 !important;
    margin-right: 0;
    margin-left: 0;

    transform: scale3d(0,0,0);
  }
  img {
    display: inline;
  }
}

.block-news .block-header .sort {
  @include mq($until: mobile) {
    display: block;
  }

  margin-bottom: 1.5em;
}

.block-news.sorted {
  .news-item {
    @include mq($until: 800px) {
      width: 50%;
      max-width: 50%;
      &:nth-child(10n), &:nth-child(10n-6) {
        width: 50%;
        max-width: 50%;
      }
    }
    @include mq($until: mobile) {
      @include aspect-ratio(2, 1);

      width: calc(100% + 3rem) !important;
      max-width: 200% !important;
      &:nth-child(10n), &:nth-child(10n-6) {
        @include aspect-ratio(2, 1);
      }
    }
    &.hide {
      width: 0 !important;
    }
    &:nth-child(10n), &:nth-child(10n-6) {
      @include aspect-ratio(1, 1);

      width: 33.3%;
      max-width: 33.3%;
    }
  }
}
