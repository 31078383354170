/*--------------------------------------------------------*\
	Universals
\*--------------------------------------------------------*/

html {
	font-size: 16px;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body, .wrapper { 
	height: 100%; 
	background: #fff;
}

*, *::before, *::after {
	box-sizing: inherit;
	-webkit-font-smoothing: inherit;
}

a {
	transition: all 0.75s $timing;
	text-decoration: none;
}

.wrapper {
	@include container(100%);
	text-align: center;
	// transition: opacity 0.75s ease;
	// opacity: 0;
	// &.clean-load {
	// 	opacity: 1;
	// }
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}

.ctn {
	@include container(74rem);
	position: relative;
	padding: 0 3.5rem;
	@include mq($until: at-640) {
		padding: 0 2rem;
	}
	@include mq($until: at-480) {
		padding: 0 1.5rem;
	}
}

.full {
	@include span(24);
}