/*--------------------------------------------------------*\
	Block
\*--------------------------------------------------------*/

.block {
  @include span(24);
  @include mq($until: at-768) {
    padding: 0 0 3.5rem 0 !important;
  }

  text-align: left;

  position: relative;
  z-index: 2;

  overflow: hidden;
  padding: 7.5rem 0;
  &.no-padding {
    padding: 0;
  }
  &.block-graphic {
    z-index: 2;

    pointer-events: none;
  }
}

.block:not([class*='bg']) {
  background-color: transparent;
}

.block-content + .block-content {
  padding-top: 0;
}

.block-header {
  @include span(24);

  margin-bottom: 1rem;
}

.block-main {
  @include span(24);
}

.block-main a {
  color: $body-link-color;
  &:hover {
    color: $grey;
  }
}

/*--------------------------------------------------------*\
	Title + Content + Button
\*--------------------------------------------------------*/

.title {
  @include span(24);
  @include rg(2rem, 1.25);

  text-align: center;

  margin-bottom: .5rem;
}

.cnt {
  @include span(16);
  @include squish(4);
  @include lt(1rem, 1.5);

  text-align: center;
}

.btn-ctn {
  @include span(24);

  text-align: center;

  margin-top: 2rem;
  padding: .75rem 0;
}

$side-animation-duration: 250ms;
$button-fade-multiplier: 2;
.btn {
  @include rg(1rem, 1);

  color: $body !important;

  position: relative;

  display: inline-block;
  padding: .75rem 1.25rem;

  border: 1px solid $body;
  transition: background-color $side-animation-duration * $button-fade-multiplier ease-in-out 0ms,
  color $side-animation-duration ease-in-out 0ms, border-color 0ms linear 0ms;
  div[class^=border] {
    position: absolute;

    background-color: $blue;
    transition: top $side-animation-duration linear, left $side-animation-duration linear, bottom $side-animation-duration linear, right $side-animation-duration linear, height 0ms linear, width 0ms linear;
    &.border-top, &.border-bottom {
      height: 0;
    }
    &.border-left, &.border-right {
      width: 0;
    }
    &.border-top {
      top: -1px;
      right: 100%;
      left: -1px;

      transition-delay: 0ms;
      transition-duration: 0ms; // $side-animation-duration * ($button-fade-multiplier + 3);
    }
    &.border-right {
      top: -1px;
      right: -1px;
      bottom: 100%;

      transition-delay: 0ms;
      transition-duration: 0ms; // $side-animation-duration * ($button-fade-multiplier + 2);
    }
    &.border-bottom {
      right: -1px;
      bottom: -1px;
      left: 100%;

      transition-delay: 0ms;
      transition-duration: 0ms; // $side-animation-duration * ($button-fade-multiplier + 1);
    }
    &.border-left {
      top: 100%;
      bottom: -1px;
      left: -1px;

      transition-delay: 0ms;
      transition-duration: 0ms; // $side-animation-duration * $button-fade-multiplier;
    }
  }
  &:hover {
    color: $blue !important;
    text-decoration: none !important;

    background-color: transparent;
    border-color: transparent;
    transition: background-color $side-animation-duration * $button-fade-multiplier ease-in-out $side-animation-duration * 4,
    color $side-animation-duration ease-in-out $side-animation-duration * 2, border-color 0ms linear 0ms;
    div[class^=border] {
      &.border-top, &.border-bottom {
        height: 1px;
      }
      &.border-left, &.border-right {
        width: 1px;
      }
      &.border-top {
        right: -1px;

        transition: top $side-animation-duration linear, left $side-animation-duration linear, bottom $side-animation-duration linear, right $side-animation-duration linear, height 0ms linear, width 0ms linear;
      }
      &.border-right {
        bottom: -1px;

        transition: top $side-animation-duration * .5 linear $side-animation-duration,
        left $side-animation-duration * .5 linear $side-animation-duration,
        bottom $side-animation-duration * .5 linear $side-animation-duration,
        right $side-animation-duration * .5 linear $side-animation-duration,
        height 0ms linear $side-animation-duration,
        width 0ms linear $side-animation-duration;
      }
      &.border-bottom {
        left: -1px;

        transition: top $side-animation-duration linear $side-animation-duration * 1.5,
        left $side-animation-duration linear $side-animation-duration * 1.5,
        bottom $side-animation-duration linear $side-animation-duration * 1.5,
        right $side-animation-duration linear $side-animation-duration * 1.5,
        height 0ms linear  $side-animation-duration * 1.5,
        width 0ms linear  $side-animation-duration * 1.5;
      }
      &.border-left {
        top: -1px;

        transition: top $side-animation-duration * .5 linear $side-animation-duration * 2.5,
        left $side-animation-duration * .5 linear $side-animation-duration * 2.5,
        bottom $side-animation-duration * .5 linear $side-animation-duration * 2.5,
        right $side-animation-duration * .5 linear $side-animation-duration * 2.5,
        height 0ms linear  $side-animation-duration * 2.5,
        width 0ms linear  $side-animation-duration * 2.5;
      }
    }
  }
  &.icon {
    display: inline-block;
    vertical-align: top;
    max-width: 40px;
    margin: 0 5rem  5rem;
    padding: 0;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
    .description {
      @include h-center();
      @include rg(.875rem, 1rem);

      color: $body;
      white-space: nowrap;

      position: absolute;
      top: 100%;

      padding-top: 40%;
    }
    .tooltip {
      @include h-center();
      @include rg(.875rem, 1rem);

      white-space: nowrap;

      position: absolute;
      top: 125%;

      display: block;
      margin-top: 13px;
      padding: .5rem;

      background: #fff;
      border: 1px solid $body;
      border-radius: .5rem;
      opacity: 0;
      transition: all 250ms $timing;
      &:after, &:before {
        position: absolute;
        bottom: 100%;
        left: 50%;

        width: 0;
        height: 0;

        border: solid transparent;
        content: ' ';
        pointer-events: none;
      }
      &:after {
        margin-left: -10px;

        border-bottom-color: #fff;
        border-color: rgba(255, 255, 255, 0);
        border-width: 10px;
      }
      &:before {
        margin-left: -11px;

        border-bottom-color: $body;
        border-color: rgba(0, 0, 0, 0);
        border-width: 11px;
      }
    }
    &:hover {
      .tooltip {
        top: 100%;

        opacity: 1;
      }
      svg {
        path {
          fill: $blue !important;
          transition: fill $side-animation-duration ease-in-out $side-animation-duration * 2;
        }
      }
    }
  }
}
