/*--------------------------------------------------------*\
  Positioning
\*--------------------------------------------------------*/

$timing: cubic-bezier(.190, 1.000, .220, 1.000);

@mixin v-center() {
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
}

@mixin h-center() {
  position: absolute;
  left: 50%;

  transform: translateX(-50%);
}

@mixin center() {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%,-50%);
}

@mixin un-center() {
  position: relative;
  top: 0;
  left: 0;

  transform: translate(0,0);
}

@mixin bg-center() {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*--------------------------------------------------------*\
  Align with .ctn
\*--------------------------------------------------------*/

@mixin ctn-absolute($dir) {
  @if ($dir == left) {
    @include mq($until: at-640) {
      left: 2rem;
    }
    @include mq($until: at-480) {
      left: 1.5rem;
    }

    left: 3.5rem;
  } @else {
    @include mq($until: at-640) {
      right: 2rem;
    }
    @include mq($until: at-480) {
      right: 1.5rem;
    }

    right: 3.5rem;
  }
}


/*--------------------------------------------------------*\
  Maintain an aspect ratio
\*--------------------------------------------------------*/

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;

    content: '';
  }
  > .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
