/*--------------------------------------------------------*\
  Footer
\*--------------------------------------------------------*/

.footer {
  position: absolute;
  z-index: 3;
  bottom: 0;

  padding: 2.5rem 0;
}

.copyright {
  @include rg(.875rem, .875rem);
  @include mq($until: mobile) {
    text-align: center;

    display: block;
  }

  position: relative;

  display: inline-block;
  p, .menu-footer-container {
    display: inline-block;
  }
  .menu-footer-container {
    pointer-events: auto;
    .menu {
      display: inline-block;
      li {
        display: inline-block;
        a {
          @include mq( mobile) {
            &:hover {
              color: $red !important;
            }
          }

          color: black;

          display: inline-block;
          &::before {
            color: black !important;

            content: ' | ';
          }
        }
      }
    }
  }
}


.social-media {
  @include ctn-absolute(right);
  @include mq($until: mobile) {
    text-align: center;

    position: relative;
    right: 0;

    margin-top: 1rem;
  }

  position: absolute;
  top: 0;

  pointer-events: auto;
  .btn.icon {
    position: relative;

    width: 2rem;
    height: 2rem;
    margin: 0 0 0 .5rem;
    padding: 1rem;
    svg {
      @include center;

      height: 1rem;
      &:hover {
        path {
          fill: $blue !important;
          transition: fill 250ms ease-in-out 500ms;
        }
      }
    }
  }
}

.about .social-media .btn.icon:hover svg path, .about .social-media .btn.icon svg:hover path {
  fill: white !important;
}

.about .social-media .btn div[class^=border] {
  background: white !important;
}

.about .copyright .menu-footer-container .menu li a:hover {
  color: white !important;
}
