/*--------------------------------------------------------*\
	Icons
\*--------------------------------------------------------*/

@font-face {
	font-family: 'icomoon';
	src:url('../assets/fonts/icomoon.eot?a0jczb');
	src:url('../assets/fonts/icomoon.eot?#iefixa0jczb') format('embedded-opentype'),
		url('../assets/fonts/icomoon.woff?a0jczb') format('woff'),
		url('../assets/fonts/icomoon.ttf?a0jczb') format('truetype'),
		url('../assets/fonts/icomoon.svg?a0jczb#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-file::before {
	content: "\e600";
}
.icon-arrow-right::before {
	content: "\e601";
}
.icon-mail::before {
	content: "\e602";
}
.icon-twitter::before {
	content: "\e603";
}
.icon-facebook::before {
	content: "\e604";
}
.icon-linkedin::before {
	content: "\e605";
}