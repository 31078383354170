/*--------------------------------------------------------*\
	Fonts -> Heading
\*--------------------------------------------------------*/

@font-face {
	font-family: 'gunny_rewrittenregular';
	src: url('./fonts/gnyrwn971-webfont.eot');
	src: url('./fonts/gnyrwn971-webfont.eot?#iefix') format('embedded-opentype'),
		 url('./fonts/gnyrwn971-webfont.woff2') format('woff2'),
		 url('./fonts/gnyrwn971-webfont.woff') format('woff'),
		 url('./fonts/gnyrwn971-webfont.ttf') format('truetype'),
		 url('./fonts/gnyrwn971-webfont.svg#gunny_rewrittenregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin heading($size, $line-height) {
	font-family: 'gunny_rewrittenregular';
	font-size: $size;
	line-height: $line-height;
}

/*--------------------------------------------------------*\
	Fonts -> Body
\*--------------------------------------------------------*/

@mixin lt($size, $line-height, $style: normal) {
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-family:'Frutiger LT W04_46 Ligh1459266', helvetica, arial;
	} @else {
		font-family:'Frutiger LT W04_45 Light', helvetica, arial;
	}
}

@mixin rg($size, $line-height, $style: normal) {
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-family:'Frutiger LT W04_56 Italic', helvetica, arial;
	} @else {
		font-family:'Frutiger LT W04_55 Roman', helvetica, arial;
	}
}

@mixin bd($size, $line-height, $style: normal) {
	font-family: 'Gotham Rounded A', 'Gotham Rounded B';
	font-weight: 700;
	font-size: $size;
	line-height: $line-height;
	@if ($style == italic) {
		font-family:'Frutiger LT W04_66 Bold Italic', helvetica, arial;
	} @else {
		font-family:'Frutiger LT W04_65 Bold', helvetica, arial;
	}
}