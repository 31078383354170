/*--------------------------------------------------------*\
  Items
\*--------------------------------------------------------*/



.block-sort {
  @include mq($until: mobile) {
    padding-top: 0;
  }
  .block-header {
    @include mq($until: mobile) {
      margin: 0;
    }
    .sort {
      @include mq($until: mobile) {
        display: none;
      }
    }
  }
  &.sort-loaded {
    .block-main {
      opacity: 1;
    }
  }
  .block-main {
    @include mq($until: mobile) {
      opacity: 1;
    }

    opacity: 0;
    transition: opacity 150ms ease-in-out 500ms;
  }
  .sort-item-mobile-header {
    @include rg(1.125rem, 2.5rem);
    @include mq($until: mobile) {
      display: block;
    }

    color: $body;
    text-align: center;

    display: none;
    margin: 0 -1.5rem;

    border: 1px solid $body;
    border-width: 1px 0;
    &.active {
      & + .sort-item-category-container {
        @include mq($until: mobile) {
          max-height: 10000px;
          // transition: max-height 0 ease-in-out;
        }
        & + .sort-item-mobile-header {
          border-top-width: 1px;
        }
      }
    }
  }
  .sort-item-category-container {
    @include mq($until: mobile) {
      position: relative;

      display: block;
      overflow: hidden;
      max-height: 0;
      margin: 0 -1.5rem;
      padding: 0 1.5rem;

      transform: translateZ(0);
      // transition: max-height 0 $timing;
    }

    position: static;

    display: inline;
    + .sort-item-mobile-header {
      border-top-width: 0;
    }
  }
}



.item-popup {
  @include mq($until: mobile) {
    right: 0;
    left: 0;

    margin-top: 0;
  }
  @include mq($until: mobile) {
    position: relative;
    z-index: 1;

    display: block;
    margin-top: 0;
    margin-top: 0;

    background: #fff;
    opacity: 1;
  }

  position: absolute;
  z-index: -1;
  right: 1%;
  left: 1%;

  display: none;
  width: 98%;

  background: rgba(white, .9);
  opacity: 0;
  header {
    @include mq($until: mobile) {
      display: none;
    }

    position: relative;

    margin-bottom: 2rem;
    padding-top: .5rem;
    padding-bottom: 1rem;

    background: url('./img/svg/rule-with-arrow.svg') no-repeat left bottom;
    background-size: 1500px 17px;
    .name {
      @include heading(2rem, 3rem);
    }
    .close {
      @include rg(1em, 1em);

      position: relative;

      float: right;
      margin-top: 16px;
      padding: 10px 25px 16px 0;

      cursor: pointer;
      transform-origin: 0, 0;
      transition: all 250ms ease-out;
      .icon, .icon:before, .icon:after {
        position: absolute;
        top: 0;

        display: block;
        width: 25px;
        height: 2px;

        background: black;
        border-radius: 1px;
        content: '';
        cursor: pointer;
        transition: all 250ms ease-out;
      }
      .icon {
        background-color: transparent;
      }
      .icon:before {
        bottom: -8px;

        transform: rotate(45deg);
      }
      .icon:after {
        bottom: -8px;

        transform: rotate(-45deg);
      }
      &:hover .icon {
        transform: rotate(90deg);
      }
    }
  }
  p {
    margin: 0 0 2em 0;
  }
  h3 {
    @include rg(1.25em, 2.5rem);
  }
  article, aside {
    display: inline-block;
    vertical-align: top;
  }
  article {
    @include rg(1em, 1.5rem);
    @include mq($until: mobile) {
      @include rg(.875em, 1.25rem);

      width: 100%;
      margin-right: 0;
    }

    position: relative;

    width: 100%;
    max-width: 1072px;
    &.has-aside {
      @include mq($until: mobile) {
        width: 100%;
        margin-right: 0;
      }

      width: (580 / 1072) * 100%;
      max-width: 580px;
      margin-right: (145 / 1072) * 100%;
    }
    .btn-ctn {
      text-align: left;
    }
    p ~ p {
      @include mq($until: mobile) {
        // display: none;
      }
    }
  }
  aside {
    @include mq($until: mobile) {
      display: none;
    }

    color: $body;

    width: (340 / 1072) * 100%;
  }
}
